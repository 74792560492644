import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import useAuth from "../../hooks/useAuth";
import MenuNotifications from "./sub-components/MenuNotificatons";
// import { getProducts, setSearchQuery } from "../../store/slices/filters-slice";

const IconGroup = ({ iconWhiteClass, notifications, error }) => {
  const searchQuery = useSelector((state) => state.filters.searchQuery);
  // const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // const { data: notifications, error, subscribeToMore } = useQuery(GET_NOTIFICATIONS)

  const { logout } = useAuth();
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  const getUnreadNotifications = () => {
    let count = 0;
    if (notifications.getNotifications) {
      notifications.getNotifications.forEach((notification) => {
        if (notification.isUnread === true) {
          count++;
        }
      });
      return count;
    }
    return;
  };
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                dispatch(setSearchQuery(e.target.value));
                dispatch(getProducts());
              }}
              placeholder="Search"
            />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {token ? (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/dashboard/my-account"}>
                    my account
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/dashboard/BecomeSeller"}>
                    Become a seller
                  </Link>
                </li>
                <li
                  onClick={() => {
                    localStorage.removeItem("token");
                    logout();
                  }}
                >
                  <Link to={process.env.PUBLIC_URL + "/login"}>logout</Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/register/user"}>
                    Register
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/register/seller"}>
                    Seller Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {token && (
        <>
          <div className="same-style header-compare">
            <Link to={process.env.PUBLIC_URL + "/dashboard/compare"}>
              <i className="pe-7s-shuffle" />
              {compareItems && compareItems.length > 0 ? (
                <span className="count-style">
                  {compareItems && compareItems.length}
                </span>
              ) : (
                ""
              )}
              {/* <span className="count-style">
                {compareItems && compareItems.length ? compareItems.length : 0}
              </span> */}
            </Link>
          </div>
          <div className="same-style header-compare">
            <Link to={process.env.PUBLIC_URL + "/dashboard/chat"}>
              <i className="pe-7s-chat" />
            </Link>
          </div>
          <div className="same-style cart-wrap d">
            <button className="icon-cart" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-bell" />
              {notifications?.getNotifications.length > 0 ? (
                ""
              ) : (
                <span className="count-style">
                  {notifications?.getNotifications && getUnreadNotifications()}
                </span>
              )}
              {/* <span className="count-style">
                {notifications?.getNotifications && getUnreadNotifications()}
              </span> */}
            </button>
            {/* menu cart */}
            <MenuNotifications
              notifications={notifications?.getNotifications || []}
              error={error}
            />
          </div>
          <div className="same-style header-wishlist">
            <Link to={process.env.PUBLIC_URL + "/dashboard/wishlist"}>
              <i className="pe-7s-like" />
              <span className="count-style">
                {wishlistItems && wishlistItems.length
                  ? wishlistItems.length
                  : 0}
              </span>
            </Link>
          </div>
        </>
      )}

      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
