import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HOST } from "../../config";
import { client } from "../../graphql/Server";
import { GET_PRODUCTS_PF } from "../../graphql/Shop";

export const PF_PRODUCT_THUNK = "fetch/printful/products";
export const getProducts = createAsyncThunk(PF_PRODUCT_THUNK, async (_, __) => {
  const state = __.getState()?.filters;
  try {
    const { data: { products } = { products: [] } } = await client.query({
      query: GET_PRODUCTS_PF,
      variables: {
        limit: state.limit,
        skip: state.skip,
        search: state.searchQuery ? state.searchQuery : null,
        categoryId: state?.categoryId || state?.categoryId || null,
        color: !!state.colors?.length ? state.colors : null,
        size: !!state.sizes?.length ? state.sizes : null,
        storeId: state.storeId ? state.storeId : null,
      },
    });

    const transformedProducts = products?.map((product) => ({
      store: product.Store,
      type: product.type,
      category: [product.Category.name],
      discount: 0,
      id: product.id,
      price: parseFloat(product?.price),
      name: product.name,
      new: false,
      offerEnd: "",
      rating: product.avgRating,
      saleCount: 0,
      image:
        product.type === "NORMAL"
          ? HOST + product.thumbnail
          : product.thumbnail,
      variation: product.Variants.map((variant) => ({
        ...variant,
        type: product.type,
        price: parseFloat(variant?.price),
        color: variant?.color,
        image:
          product.type === "NORMAL"
            ? HOST + variant.thumbnail_url ||
              HOST + variant.preview_url ||
              HOST + product.thumbnail
            : variant?.thumbnail_url ||
              variant?.preview_url ||
              product?.thumbnail,
        preview_url:
          product.type === "NORMAL"
            ? HOST + variant?.preview_url || HOST + product.thumbnail
            : variant?.preview_url || product?.thumbnail,
        thumbnail_url:
          product.type === "NORMAL"
            ? HOST + variant.thumbnail_url || HOST + product.thumbnail
            : variant?.thumbnail_url || product?.thumbnail,
        size: variant?.size,
      })),
    }));

    return transformedProducts;
  } catch (error) {
    console.error("Error fetching products:", error);
    return __.rejectWithValue("Failed to fetch products");
  }
});

export const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    limit: 8,
    skip: 0,
    products: [],
    colors: [],
    sizes: [],
    categoryId: null,
    searchQuery: null,
    isLoading: false,
    error: null,
    hasMore: false,
    sortBy: null,
  },
  reducers: {
    resetFilters(state) {
      state.colors = [];
      state.sizes = [];
      state.searchQuery = "";
      state.products = [];
      state.hasMore = false;
      state.categoryId = null;
      state.skip = 0;
    },
    resetPartialFilters(state) {
      state.products = [];
      state.hasMore = false;
      state.skip = 0;
    },
    setCategoryId(state, action) {
      state.categoryId = action.payload;
    },
    toggleColor(state, action) {
      const exists = state.colors.includes(action.payload);
      state.colors = exists
        ? state.colors.filter((color) => color !== action.payload)
        : [...state.colors, action.payload];
    },
    toggleSize(state, action) {
      const exists = state.sizes.includes(action.payload);
      state.sizes = exists
        ? state.sizes.filter((size) => size !== action.payload)
        : [...state.sizes, action.payload];
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    resetProducts(state) {
      state.products = [];
      state.hasMore = true;
    },
    increaseProductsLimit(state, action) {
      state.skip = action.payload || state.skip + 8;
    },
    setPage(state, action) {
      state.skip = action.payload * state.limit;
    },

    setSortByProducts(state, action) {
      switch (action.payload) {
        case "priceHighToLow":
          state.products = [...state.products].sort((p1, p2) => {
            const priceP1 = p1?.price || 0;
            const priceP2 = p2?.price || 0;
            return priceP2 - priceP1;
          });
          break;
        case "priceLowToHigh":
          state.products = [...state.products].sort((p1, p2) => {
            const priceP1 = p1?.price || 0;
            const priceP2 = p2?.price || 0;
            return priceP1 - priceP2;
          });
          break;
        case "default":
        default:
          break;
      }
    },
  },
  selectors: {
    productsSelector(state) {
      return state.products;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.length === 0) {
          state.hasMore = false;
          return;
        }
        state.hasMore = true;
        state.products = [...state.products, ...action.payload];
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setPage,
  toggleColor,
  toggleSize,
  setSearchQuery,
  resetProducts,
  setSortByProducts,
  resetFilters,
  setCategoryId,
  increaseProductsLimit,
  resetPartialFilters,
} = filtersSlice.actions;
export const { productsSelector } = filtersSlice.selectors;
export default filtersSlice.reducer;
