import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";
import { useEffect, useState } from "react";

const LanguageCurrencyChanger = ({ currency }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [isloadindlang, setIsloadindlang] = useState(true);
  // Language loading state
  const dispatch = useDispatch();

  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value;

    i18n.changeLanguage(languageCode, () => {
      // Hide loader once the language is changed
    });
  };

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value;
    dispatch(setCurrency(currencyName));
  };

  function googleTranslateElementInit(lng) {
    setLanguage(lng);

    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,ar",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
  }

  useEffect(() => {
    if (window.google) {
      googleTranslateElementInit();
      setIsloadindlang(false);
    } else {
      setIsloadindlang(true);
    }
  }, []);

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <div id="google_translate_element">
          {isloadindlang && (
            <h4
              style={{
                fontSize: "10px",
              }}
            >
              Loading language...
            </h4>
          )}
        </div>
      </div>

      <div className="same-language-currency use-style">
        <span>
          {currency.currencyName} <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="USD" onClick={setCurrencyTrigger}>
                USD
              </button>
            </li>
            <li>
              <button value="SAR" onClick={setCurrencyTrigger}>
                SAR
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="same-language-currency">
        <p>Call Us 3965410</p>
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  currency: PropTypes.shape({
    currencyName: PropTypes.string,
  }),
};

export default LanguageCurrencyChanger;
