import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <NavLink
              style={({ isActive }) => ({ color: isActive ? "blue" : "" })}
              to={process.env.PUBLIC_URL + "/"}
            >
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => ({ color: isActive ? "blue" : "" })}
              to={process.env.PUBLIC_URL + "/shop"}
            >
              {t("shop")}
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => ({ color: isActive ? "blue" : "" })}
              to={process.env.PUBLIC_URL + "/stores"}
            >
              {t("Stores")}
            </NavLink>
          </li>

          {/* <li>
            <NavLink style={({ isActive }) => ({ color: isActive ? "blue" : "" })} to={process.env.PUBLIC_URL + "/blog"}>
              {t("blog")}
            
            </NavLink>
        
          </li> */}
          <li>
            <NavLink
              style={({ isActive }) => ({ color: isActive ? "blue" : "" })}
              to={process.env.PUBLIC_URL + "/contact"}
            >
              {t("contact_us")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
